// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hljs {
    display: block;
    overflow-x: auto;
    padding: 0.5em;
    background: #3a2234;
    color: #939eeb;
    font-family: "Geist Mono";
}

.hljs-keyword,
.hljs-built_in,
.hljs-title.function_,
.hljs-title.class_,
.hljs-params,
.hljs-meta {
    color: #939eeb;
    font-weight: 500;
}

.hljs-symbol {
    color: white;
}

.hljs-string,
.hljs-subst,
.hljs-attr-name {
    color: #ff8087;
}

.hljs-ln-numbers {
    color: #ffffff99;
    font-weight: 500;
    text-align: right;
}

.hljs-number {
    color: #5af2ba;
}

.hljs-white-char,
.hljs-params .hljs-white-char {
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/styles/highlighter-theme.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,gBAAgB;IAChB,cAAc;IACd,mBAAmB;IACnB,cAAc;IACd,yBAAyB;AAC7B;;AAEA;;;;;;IAMI,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,YAAY;AAChB;;AAEA;;;IAGI,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,cAAc;AAClB;;AAEA;;IAEI,YAAY;AAChB","sourcesContent":[".hljs {\n    display: block;\n    overflow-x: auto;\n    padding: 0.5em;\n    background: #3a2234;\n    color: #939eeb;\n    font-family: \"Geist Mono\";\n}\n\n.hljs-keyword,\n.hljs-built_in,\n.hljs-title.function_,\n.hljs-title.class_,\n.hljs-params,\n.hljs-meta {\n    color: #939eeb;\n    font-weight: 500;\n}\n\n.hljs-symbol {\n    color: white;\n}\n\n.hljs-string,\n.hljs-subst,\n.hljs-attr-name {\n    color: #ff8087;\n}\n\n.hljs-ln-numbers {\n    color: #ffffff99;\n    font-weight: 500;\n    text-align: right;\n}\n\n.hljs-number {\n    color: #5af2ba;\n}\n\n.hljs-white-char,\n.hljs-params .hljs-white-char {\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#preview-stacked-cards {
    --cards: 3;
    --cardTopPadding: 3rem;
    --cardMargin: 1rem;
    list-style: none;
    padding-left: 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(var(--cards), 1fr);
    gap: var(--cardMargin);
}

#preview-stacked-cards>div:nth-child(1) {
    --index: 0;
}

#preview-stacked-cards>div:nth-child(2) {
    --index: 1;
}

#preview-stacked-cards>div:nth-child(3) {
    --index: 2;
}

#preview-stacked-cards>div {
    position: sticky;
    top: 0;
    padding-top: calc(var(--index) * var(--cardTopPadding));
}

@media screen and (max-width: 1024px) {
    #preview-stacked-cards {
        --cardTopPadding: 3rem;
        --cardMargin: 1rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/preview-stack.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,sBAAsB;IACtB,kBAAkB;IAIlB,gBAAgB;IAChB,eAAe;IACf,aAAa;IACb,0BAA0B;IAC1B,6CAA6C;IAC7C,sBAAsB;AAR1B;;AAWA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,gBAAgB;IAChB,MAAM;IACN,uDAAuD;AAC3D;;AAEA;IACI;QACI,sBAAsB;QACtB,kBAAkB;IACtB;AACJ","sourcesContent":["#preview-stacked-cards {\n    --cards: 3;\n    --cardTopPadding: 3rem;\n    --cardMargin: 1rem;\n}\n\n#preview-stacked-cards {\n    list-style: none;\n    padding-left: 0;\n    display: grid;\n    grid-template-columns: 1fr;\n    grid-template-rows: repeat(var(--cards), 1fr);\n    gap: var(--cardMargin);\n}\n\n#preview-stacked-cards>div:nth-child(1) {\n    --index: 0;\n}\n\n#preview-stacked-cards>div:nth-child(2) {\n    --index: 1;\n}\n\n#preview-stacked-cards>div:nth-child(3) {\n    --index: 2;\n}\n\n#preview-stacked-cards>div {\n    position: sticky;\n    top: 0;\n    padding-top: calc(var(--index) * var(--cardTopPadding));\n}\n\n@media screen and (max-width: 1024px) {\n    #preview-stacked-cards {\n        --cardTopPadding: 3rem;\n        --cardMargin: 1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

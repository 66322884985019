// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --cards: 3;
    --cardTopPadding: 6rem;
    --cardsHeaderBottomPadding: 24rem;
    --cardsSectionGap: 2rem;
    --cardMargin: 2rem;
    --cardsSectionPaddingBottom: calc( calc(var(--cards) - 1) * var(--cardTopPadding));
    --cardsHeaderBottomStop: 448px;
}

#stacked-cards {
    list-style: none;
    padding-left: 0;
    gap: var(--cardMargin);
    padding-bottom: calc(calc(var(--cards) - 1) * var(--cardTopPadding));
    display: flex;
    flex-direction: column;
}

#stacked-cards>div:nth-child(1) {
    --index: 0;
}

#stacked-cards>div:nth-child(2) {
    --index: 1;
}

#stacked-cards>div:nth-child(3) {
    --index: 2;
}

#stacked-cards>div {
    transform-origin: bottom;
    position: sticky;
    top: 0;
}

@media screen and (max-width: 1024px) {
    :root {
        --cardTopPadding: 3rem;
        --cardsHeaderBottomPadding: 10rem;
        --cardsSectionGap: 4rem;
        --cardsHeaderBottomStop: 272px;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/stack.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,sBAAsB;IACtB,iCAAiC;IACjC,uBAAuB;IACvB,kBAAkB;IAClB,kFAAkF;IAClF,8BAA8B;AAClC;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,sBAAsB;IACtB,oEAAoE;IACpE,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,wBAAwB;IACxB,gBAAgB;IAChB,MAAM;AACV;;AAEA;IACI;QACI,sBAAsB;QACtB,iCAAiC;QACjC,uBAAuB;QACvB,8BAA8B;IAClC;AACJ","sourcesContent":[":root {\n    --cards: 3;\n    --cardTopPadding: 6rem;\n    --cardsHeaderBottomPadding: 24rem;\n    --cardsSectionGap: 2rem;\n    --cardMargin: 2rem;\n    --cardsSectionPaddingBottom: calc( calc(var(--cards) - 1) * var(--cardTopPadding));\n    --cardsHeaderBottomStop: 448px;\n}\n\n#stacked-cards {\n    list-style: none;\n    padding-left: 0;\n    gap: var(--cardMargin);\n    padding-bottom: calc(calc(var(--cards) - 1) * var(--cardTopPadding));\n    display: flex;\n    flex-direction: column;\n}\n\n#stacked-cards>div:nth-child(1) {\n    --index: 0;\n}\n\n#stacked-cards>div:nth-child(2) {\n    --index: 1;\n}\n\n#stacked-cards>div:nth-child(3) {\n    --index: 2;\n}\n\n#stacked-cards>div {\n    transform-origin: bottom;\n    position: sticky;\n    top: 0;\n}\n\n@media screen and (max-width: 1024px) {\n    :root {\n        --cardTopPadding: 3rem;\n        --cardsHeaderBottomPadding: 10rem;\n        --cardsSectionGap: 4rem;\n        --cardsHeaderBottomStop: 272px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

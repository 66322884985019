import React, {
    useState,
    useRef,
    useEffect
} from 'react';
import { Link } from 'react-router-dom';
import {
    sqwish,
    tokenize,
    llm_inference_stream
} from './api';  // Updated import

import sqwishLogo from './images/sqwish-white-off-thick-high-res.webp';
import playgroundLogo2 from './images/playground-sqwish-2.webp';
import playgroundLogoPlayground from './images/playground-sqwish-header-playground.webp';
import backgroundImage from './images/noise_overlay-7.webp';

import ChatBubble from './ChatBubble';
import SearchBar from './SearchBar';
import { CSSTransition } from 'react-transition-group';
import AcceptModal from './AcceptModal'; // Import the new modal component
import { acceptPlaygroundTermsClick, accessClick } from '../analytics';

const INFERENCE_MODEL = "gpt4o-mini";

const Playground: React.FC = () => {
    // ----------------------------------------------------------------------------
    //                                  REFS
    // ----------------------------------------------------------------------------
    const nodeRef = useRef(null);
    const deltasComputedRef = useRef(false);
    const originalPromptRef = useRef<HTMLDivElement | null>(null);
    const compressedPromptRef = useRef<HTMLDivElement | null>(null);

    // ----------------------------------------------------------------------------
    //                                STATE
    // ----------------------------------------------------------------------------
    const [inputText, setInputText] = useState('');
    const [originalText, setOriginalText] = useState<string | null>(null);
    const [originalResponse, setOriginalResponse] = useState<string | null>(null);
    const [sqwishText, setSqwishText] = useState<string | null>(null);
    const [sqwishResponse, setSqwishResponse] = useState<string | null>(null);
    const [loading, setLoading] = useState({
        original_reponse: false,
        sqwish: false,
        sqwish_reponse: false
    });
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isSwitchOn, setIsSwitchOn] = useState(true);
    const [hasCompressed, setHasCompressed] = useState(false);

    // Toggling the stats menu on the right (desktop)
    // For mobile, we’ll reuse the same state, but show/hide a bottom drawer instead
    const [isStatsOpen, setIsStatsOpen] = useState(true);

    // --- Streaming state for "original" response ---
    const [streamPartialOriginal, setStreamPartialOriginal] = useState<string>('');
    const [streamOriginalStats, setStreamOriginalStats] = useState<{
        throughput: number;
        TTFT: number;
        number_tokens: number;
        total_latency: number;
    } | null>(null);
    const [streamingInProgress, setStreamingInProgress] = useState(false);

    // --- Streaming state for "sqwish" response ---
    const [streamPartialSqwish, setStreamPartialSqwish] = useState<string>('');
    const [streamSqwishStats, setStreamSqwishStats] = useState<{
        throughput: number;
        TTFT: number;
        number_tokens: number;
        total_latency: number;
    } | null>(null);
    const [streamingSqwishInProgress, setStreamingSqwishInProgress] = useState(false);

    // Check acceptance from localStorage
    const [hasAcceptedTerms, setHasAcceptedTerms] = useState<boolean>(() => {
        return localStorage.getItem('hasAcceptedTerms') === 'true';
    });

    // Stats for original text
    const [originalTextStats, setOriginalTextStats] = useState<{
        number_tokens: number | null
    } | null>(null);

    // Final stats for the original response (we’re adding total_latency here)
    const [originalResponseStats, setOriginalResponseStats] = useState<{
        throughput: number;
        TTFT: number;
        total_latency: number;
    } | null>(null);

    // Stats for the compressed prompt
    const [sqwishTextStats, setSqwishTextStats] = useState<{
        number_tokens: number;
        throughput: number;
        total_latency: number;
    } | null>(null);

    // Final stats for the compressed response, including deltas (we’re adding total_latency + delta)
    const [sqwishResponseStats, setSqwishResponseStats] = useState<{
        throughput: number;
        throughput_delta: number | null;
        TTFT: number;
        TTFT_delta: number | null;
        total_latency: number;
        total_latency_delta: number | null;
    } | null>(null);

    // ----------------------------------------------------------------------------
    //                           FUNCTIONS
    // ----------------------------------------------------------------------------

    // ------------------------------------------------------------------
    //                          TOKENIZE ORIGINAL
    // ------------------------------------------------------------------
    const tokenize_original = async (inputText: string) => {
        try {
            const tokensCountResponse = await tokenize(inputText, INFERENCE_MODEL);
            setOriginalTextStats({ number_tokens: tokensCountResponse.tokens_count });
        } catch (error) {
            setErrorMessage('Error fetching tokenizer response.');
        }
    };

    // ------------------------------------------------------------------
    //              STREAM THE "ORIGINAL" RESPONSE
    // ------------------------------------------------------------------
    const stream_original_response = async (inputText: string) => {
        setStreamingInProgress(true);
        setStreamPartialOriginal('');
        setStreamOriginalStats(null);

        try {
            await llm_inference_stream(
                inputText,
                INFERENCE_MODEL,
                (partial) => {
                    // partial chunk arrived
                    setStreamPartialOriginal(partial);
                },
                (fullContent, finalStats) => {
                    // done streaming
                    setStreamingInProgress(false);
                    setStreamPartialOriginal(fullContent);

                    if (finalStats) {
                        // Store streaming stats
                        setStreamOriginalStats({
                            throughput: finalStats.throughput,
                            TTFT: finalStats.TTFT,
                            number_tokens: finalStats.number_tokens,
                            total_latency: finalStats.total_latency,
                        });
                        // Mirror final stats to originalResponseStats for delta calculations
                        setOriginalResponseStats({
                            throughput: finalStats.throughput,
                            TTFT: finalStats.TTFT,
                            total_latency: finalStats.total_latency,
                        });
                    }

                    // Mark loading done
                    setLoading((prev) => ({ ...prev, original_reponse: false }));
                },
                (err) => {
                    setStreamingInProgress(false);
                    setErrorMessage(`Error streaming LLM response: ${String(err)}`);
                    setLoading((prev) => ({ ...prev, original_reponse: false }));
                }
            );
        } catch (error: any) {
            setStreamingInProgress(false);
            setErrorMessage(`Error: ${error?.message || error}`);
            setLoading((prev) => ({ ...prev, original_reponse: false }));
        }
    };

    // ------------------------------------------------------------------
    //              STREAM THE "SQWISH" RESPONSE
    // ------------------------------------------------------------------
    const stream_sqwish_response = async (sqwishPrompt: string) => {
        setStreamingSqwishInProgress(true);
        setStreamPartialSqwish('');
        setStreamSqwishStats(null);

        try {
            await llm_inference_stream(
                sqwishPrompt,
                INFERENCE_MODEL,
                (partial) => {
                    // partial chunk arrived
                    setStreamPartialSqwish(partial);
                },
                (fullContent, finalStats) => {
                    setStreamingSqwishInProgress(false);
                    setStreamPartialSqwish(fullContent);

                    if (finalStats) {
                        // We store them for comparison to the original
                        setSqwishResponseStats((prev) => ({
                            throughput: finalStats.throughput,
                            TTFT: finalStats.TTFT,
                            total_latency: finalStats.total_latency,
                            throughput_delta: null,
                            TTFT_delta: null,
                            total_latency_delta: null,
                        }));
                        setStreamSqwishStats({
                            throughput: finalStats.throughput,
                            TTFT: finalStats.TTFT,
                            number_tokens: finalStats.number_tokens,
                            total_latency: finalStats.total_latency,
                        });
                    }

                    // Mark loading done
                    setLoading((prev) => ({ ...prev, sqwish_reponse: false }));
                },
                (err) => {
                    setStreamingSqwishInProgress(false);
                    setErrorMessage(`Error streaming Sqwish response: ${String(err)}`);
                    setLoading((prev) => ({ ...prev, sqwish_reponse: false }));
                }
            );
        } catch (error: any) {
            setStreamingSqwishInProgress(false);
            setErrorMessage(`Error: ${error?.message || error}`);
            setLoading((prev) => ({ ...prev, sqwish_reponse: false }));
        }
    };

    // ------------------------------------------------------------------
    //          Compress user text and then get both responses
    // ------------------------------------------------------------------
    const sqwish_response = async (inputText: string) => {
        try {
            const sqwishTextResult = await sqwish(inputText);
            setSqwishText(sqwishTextResult.response);
            setSqwishTextStats({
                number_tokens: sqwishTextResult.number_tokens,
                throughput: sqwishTextResult.throughput,
                total_latency: sqwishTextResult.total_latency
            });
            setLoading((prev) => ({
                ...prev,
                sqwish: false,
                sqwish_reponse: true
            }));

            // Stream the "original" response for the original text
            stream_original_response(inputText);

            // Stream the "sqwish" response for the compressed text
            await stream_sqwish_response(sqwishTextResult.response);
        } catch (error) {
            setErrorMessage('Error fetching Sqwish response.');
            setLoading((prev) => ({
                ...prev,
                sqwish: false,
                sqwish_reponse: false
            }));
        }
    };

    // ----------------------------------------------------------------------------
    //                              SIDE EFFECTS
    // ----------------------------------------------------------------------------
    // ------------------------------------------------------------------
    // Once we have final stats for both original & sqwish, compute deltas:
    // (only triggered once per submission)
    // ------------------------------------------------------------------
    useEffect(() => {
        if (
            sqwishResponseStats &&
            originalResponseStats &&
            !deltasComputedRef.current
        ) {
            setSqwishResponseStats((prevStats) => {
                if (!prevStats) return null;
                return {
                    ...prevStats,
                    TTFT_delta: prevStats.TTFT - originalResponseStats.TTFT,
                    throughput_delta: prevStats.throughput - originalResponseStats.throughput,
                    total_latency_delta: prevStats.total_latency - originalResponseStats.total_latency
                };
            });
            deltasComputedRef.current = true;
        }
    }, [sqwishResponseStats, originalResponseStats]);

    // ----------------------------------------------------------------------------
    //                           EVENT HANDLERS
    // ----------------------------------------------------------------------------
    // ------------------------------------------------------------------
    //                     HANDLE SUBMIT
    // ------------------------------------------------------------------
    const handleSubmit = async () => {
        if (!inputText.trim()) {
            return;
        }
        setOriginalText(inputText);
        setOriginalTextStats(null);
        setOriginalResponse(null);
        setOriginalResponseStats(null);
        setSqwishText(null);
        setSqwishTextStats(null);
        setSqwishResponse(null);
        setSqwishResponseStats(null);
        setErrorMessage(null);

        // Clear streaming states for new submission
        setStreamPartialOriginal('');
        setStreamOriginalStats(null);
        setStreamingInProgress(false);

        setStreamPartialSqwish('');
        setStreamSqwishStats(null);
        setStreamingSqwishInProgress(false);

        // We'll set loading states
        setLoading({
            original_reponse: true,
            sqwish: true,
            sqwish_reponse: false
        });
        deltasComputedRef.current = false;

        // Tokenize user input + run sqwish
        await Promise.all([
            tokenize_original(inputText),
            sqwish_response(inputText)
        ]);
    };

    // ----------------------------------------------------------------------------
    //                       STATS CALCULATIONS / HELPERS
    // ----------------------------------------------------------------------------
    const calculateTokensCompressionPercent = (): string | null => {
        if (originalTextStats?.number_tokens && sqwishTextStats?.number_tokens) {
            const saved = originalTextStats.number_tokens - sqwishTextStats.number_tokens;
            const percent = (saved / originalTextStats.number_tokens) * 100;
            return `${percent.toFixed(0)}`;
        }
        return null;
    };

    // How many tokens saved in raw terms
    const calculateTokensSaved = (): number | null => {
        if (originalTextStats?.number_tokens && sqwishTextStats?.number_tokens) {
            return originalTextStats.number_tokens - sqwishTextStats.number_tokens;
        }
        return null;
    };

    // Returns improvement in TTFT if negative => improvement (since TTFT got smaller)
    const computeTTFTImprovementMs = (): number | null => {
        if (
            sqwishResponseStats?.TTFT_delta !== null &&
            typeof sqwishResponseStats?.TTFT_delta !== 'undefined'
        ) {
            // Negative means sqwish is faster, so improvement in ms = -TTFT_delta
            return -1 * sqwishResponseStats.TTFT_delta;
        }
        return null;
    };

    // Returns improvement in tokens/s => if throughput_delta is positive => improved
    const computeThroughputImprovement = (): number | null => {
        if (
            sqwishResponseStats?.throughput_delta !== null &&
            typeof sqwishResponseStats?.throughput_delta !== 'undefined'
        ) {
            // If throughput_delta is positive => we improved
            return sqwishResponseStats.throughput_delta;
        }
        return null;
    };

    // Returns improvement in total latency => if total_latency_delta is negative => improvement
    const computeLatencyImprovement = (): number | null => {
        if (
            sqwishResponseStats?.total_latency_delta !== null &&
            typeof sqwishResponseStats?.total_latency_delta !== 'undefined'
        ) {
            // Negative means sqwish is faster, so improvement in ms = -total_latency_delta
            return -1 * sqwishResponseStats.total_latency_delta;
        }
        return null;
    };

    const format_original_text_stats = (
        originalTextStats: { number_tokens: number | null } | null
    ) => {
        return originalTextStats ? (
            <div>{Math.floor(originalTextStats.number_tokens || 0)} tokens</div>
        ) : (
            <div></div>
        );
    };

    const format_response_stats = (
        originalResponseStats: { throughput: number; TTFT: number; total_latency: number } | null
    ) => {
        return originalResponseStats ? (
            <div>
                {Math.floor(originalResponseStats.throughput || 0)} tokens/s |{' '}
                TTFT: {Math.floor(originalResponseStats.TTFT || 0)} ms
            </div>
        ) : (
            <div></div>
        );
    };

    const format_sqwish_text_stats = (
        sqwishTextStats: {
            number_tokens: number;
            throughput: number;
            total_latency: number;
        } | null
    ) => {
        return sqwishTextStats ? (
            <div>
                {Math.floor(sqwishTextStats.number_tokens)} tokens
                {/* {Math.floor(sqwishTextStats.throughput)} tokens/s |{' '}
                {Math.floor(sqwishTextStats.total_latency)} ms */}
            </div>
        ) : (
            <div></div>
        );
    };

    const handleSignupLinkClick = () => {
        accessClick('playground');
    };

    // ----------------------------------------------------------------------------
    //                        ACCEPT TERMS HANDLER
    // ----------------------------------------------------------------------------
    const handleAccept = () => {
        localStorage.setItem('hasAcceptedTerms', 'true');
        acceptPlaygroundTermsClick();
        setHasAcceptedTerms(true);
    };

    // ----------------------------------------------------------------------------
    //                         RENDER HELPER: STATS CONTENT
    // ----------------------------------------------------------------------------
    // We’ll factor out the stats “cards” content so we can reuse it for desktop & mobile
    const renderStatsContent = () => {
        return (
            <div className="mt-2">
                <h3 className="font-bold text-lg text-center px-2">Performance Stats</h3>
                <div className="w-full flex flex-col gap-4 overflow-y-auto py-4 px-2">
                    {/* 1) ALWAYS show the "Token Savings" box at the top, even if negative */}
                    {(() => {
                        const tokenSavingsVal = calculateTokensSaved();
                        if (
                            sqwishTextStats &&
                            originalTextStats &&
                            tokenSavingsVal !== null
                        ) {
                            return (
                                <StatCard
                                    title="tokens saved"
                                    value={tokenSavingsVal}
                                    suffix="tokens"
                                    percentage={calculateTokensCompressionPercent()}
                                />
                            );
                        }
                        return null;
                    })()}

                    {/*
                      2) Gather the other stats: total latency, throughput, TTFT.
                         We'll store them in an array, then split them into positive
                         and negative for ordering + styling.
                    */}
                    {(() => {
                        interface StatItem {
                            title: string;
                            value: number;
                            suffix: string;
                            baseArrow: 'up' | 'down';
                        }

                        const stats: StatItem[] = [];

                        // 2a) Total latency improvement
                        const latencyVal = computeLatencyImprovement();
                        if (sqwishResponseStats && streamSqwishStats && latencyVal !== null) {
                            stats.push({
                                title: 'total latency',
                                value: latencyVal,
                                suffix: 'ms',
                                baseArrow: 'down', // “down” means good
                            });
                        }

                        // 2b) Throughput gain
                        const throughputVal = computeThroughputImprovement();
                        if (sqwishResponseStats && originalResponseStats && throughputVal !== null) {
                            stats.push({
                                title: 'throughput',
                                value: throughputVal,
                                suffix: 'tokens/s',
                                baseArrow: 'up', // “up” means good
                            });
                        }

                        // 2c) TTFT improvement
                        const ttftVal = computeTTFTImprovementMs();
                        if (sqwishResponseStats && originalResponseStats && ttftVal !== null) {
                            stats.push({
                                title: 'time-to-first-token',
                                value: ttftVal,
                                suffix: 'ms',
                                baseArrow: 'down', // “down” means good
                            });
                        }

                        // Separate into positives + negatives
                        const positives = stats.filter((s) => s.value > 0);
                        const negatives = stats.filter((s) => s.value < 0);

                        // Sort negatives by ascending absolute value
                        negatives.sort((a, b) => Math.abs(a.value) - Math.abs(b.value));

                        return (
                            <>
                                {positives.map((stat) => (
                                    <StatCard
                                        key={stat.title}
                                        title={stat.title}
                                        value={stat.value}
                                        suffix={stat.suffix}
                                        arrow={stat.baseArrow}
                                        isVisible
                                    />
                                ))}

                                {/* {negatives.map((stat) => (
                                    <StatCard
                                        key={stat.title}
                                        title={stat.title}
                                        value={stat.value}
                                        suffix={stat.suffix}
                                        arrow={stat.baseArrow}
                                        isVisible
                                    />
                                ))} */}

                                {originalResponseStats && (originalTextStats?.number_tokens !== null && originalTextStats?.number_tokens! < 5000) &&
                                    <div className={`w-full p-3 rounded-[20px] shadow-[inset_0px_2px_4px_rgba(255,255,255,0.05),2px_4px_8px_rgba(0,0,0,0.3)] bg-gray-200`}>
                                        {/* <div className="text-sm text-gray-700 font-bold mb-1 px-2 text-center">{"Warning"}</div> */}
                                        <div className="flex flex-row items-baseline gap-1 justify-center px-1">
                                            <>
                                                <span className="text-xs text-gray-600"><strong className="text-xs italic">{"Note: "}</strong>{"For a clear latency boost, consider inputs of 5k tokens or above."}</span>
                                            </>
                                        </div>
                                    </div>
                                }
                            </>
                        );
                    })()}
                </div>
            </div>
        );
    };

    // ----------------------------------------------------------------------------
    //                                RENDER
    // ----------------------------------------------------------------------------
    return (
        <div className="relative w-full h-full bg-gray-100">
            <div className="absolute inset-0 overflow-hidden z-0">
                <img
                    src={backgroundImage}
                    alt="background"
                    className="w-full h-full"
                />
            </div>

            {/* Footer links */}
            <div className="flex flex-col lg:flex-row absolute bottom-3 right-5 gap-2 lg:gap-5 text-right z-40">
                <Link
                    to="/privacy-policy"
                    className="text-sm items-center text-gray-500 hover:text-gray-700"
                >
                    Privacy Policy
                </Link>
                <Link
                    to="/playground/terms-and-conditions"
                    className="text-sm items-center text-gray-500 hover:text-gray-700"
                >
                    Terms and Conditions
                </Link>
            </div>

            {/* Get access button */}
            <div className="absolute top-4 right-4 flex justify-end">
                <Link
                    to="/access"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleSignupLinkClick}
                    className="shadow-md hover:shadow-lg cursor-pointer bg-black text-white py-2 px-4 s-body rounded-[62.5rem] hover:bg-black/70 transition-colors duration-300 h-10 flex items-center justify-center z-40"
                >
                    Get access
                </Link>
            </div>

            {/* Main content */}
            <div
                className={`pt-14 lg:pt-0 h-full flex flex-col items-center lg:justify-center w-full overflow-x-auto overflow-y-auto ${!hasCompressed ? 'justify-center' : ''
                    }`}
            >
                <div
                    className={`relative w-full flex flex-col items-center justify-center transition-transform duration-500 z-10`}
                >
                    {!hasCompressed && (
                        <Link to="/" className="-mt-20 relative z-20">
                            <img
                                src={playgroundLogo2}
                                alt="Sqwish Logo"
                                className="h-[52px] lg:h-[62px] w-auto lg:mb-4"
                            />
                        </Link>
                    )}
                    {hasCompressed && (
                        <Link
                            to="/playground"
                            className="hidden lg:block -mb-4 2xl:-mb-3.5 -mt-14 relative z-20"
                            onClick={(e) => {
                                // Prevent React Router from handling the navigation client-side:
                                e.preventDefault();
                                // Force the browser to do a full reload of the page:
                                window.location.reload();
                            }}
                        >
                            <div className="flex flex-row gap-4 items-center h-[65px]">
                                <img
                                    src={playgroundLogoPlayground}
                                    alt="Playground"
                                    className="mt-5 2xl:mt-3 h-[35px] w-auto"
                                />
                            </div>
                        </Link>
                    )}

                    {/* Search Bar */}
                    <SearchBar
                        inputText={inputText}
                        setInputText={setInputText}
                        handleSubmit={handleSubmit}
                        errorMessage={errorMessage}
                        hasCompressed={hasCompressed}
                        setHasCompressed={setHasCompressed}
                        isCompressEnabled={!streamingInProgress && !streamingSqwishInProgress}
                    />

                    {/* Chat Bubbles area */}
                    {hasCompressed && (
                        <div className={`transition-all duration-500 w-full z-0 px-8`}>
                            <div className={`transition-all duration-500 w-full h-full ${isStatsOpen ? 'pr-0 lg:pr-48' : 'pr-0'}`}>
                                <div className="relative w-full flex flex-col lg:flex-row gap-4 items-center justify-center">
                                    {/* LEFT BUBBLE (Original Prompt + Original LLM Response) */}
                                    <div className="relative flex flex-col items-start lg:w-[50%] max-w-xl h-[80vh] min-h-[450px] bg-pink px-6 rounded-[20px]">
                                        {/* loader dots if streaming in progress */}
                                        {((isSwitchOn && loading.original_reponse) ||
                                            streamingInProgress) && (
                                                <div className="self-end pr-6 lg:pr-0 lg:self-center absolute top-[26px]">
                                                    <div className="dot-typing-loader"></div>
                                                </div>
                                            )}
                                        <div className="p-2 text-4xl h-14 font-bold" />
                                        <div className="w-full h-full flex flex-col">
                                            {/* Original User Prompt */}
                                            {originalText && (
                                                <div
                                                    ref={originalPromptRef}
                                                    className="w-full flex flex-col"
                                                >
                                                    <ChatBubble
                                                        key={isSwitchOn ? 'on' : 'off'}
                                                        maxHeight={isSwitchOn ? '28vh' : '70vh'}
                                                        text={originalText}
                                                        role="user"
                                                        targetRGBA="rgba(250, 234, 247, 1)"
                                                        stats={format_original_text_stats(originalTextStats)}
                                                    />
                                                </div>
                                            )}

                                            {/* Original LLM response bubble (streaming or final) */}
                                            {isSwitchOn && (
                                                <div className="absolute w-full flex flex-col bottom-0">
                                                    {(streamPartialOriginal || streamingInProgress) ? (
                                                        <ChatBubble
                                                            maxHeight="45vh"
                                                            text={streamPartialOriginal}
                                                            role={INFERENCE_MODEL}
                                                            targetRGBA="rgba(244, 204, 236, 1)"
                                                            stats={
                                                                streamOriginalStats
                                                                    ? format_response_stats({
                                                                        throughput: streamOriginalStats.throughput,
                                                                        TTFT: streamOriginalStats.TTFT,
                                                                        total_latency: streamOriginalStats.total_latency,
                                                                    })
                                                                    : undefined
                                                            }
                                                        />
                                                    ) : (
                                                        originalResponse && (
                                                            <ChatBubble
                                                                maxHeight="45vh"
                                                                text={originalResponse}
                                                                role={INFERENCE_MODEL}
                                                                targetRGBA="rgba(244, 204, 236, 1)"
                                                                stats={
                                                                    originalResponseStats
                                                                        ? format_response_stats(
                                                                            originalResponseStats
                                                                        )
                                                                        : undefined
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    {/* RIGHT BUBBLE (Sqwish + Compressed LLM Response) */}
                                    <div className="relative flex flex-col items-start lg:w-[50%] max-w-xl h-[80vh] min-h-[450px] bg-yellow px-6 rounded-[20px]">
                                        {/*
                                          DESKTOP STATS: Shown on lg+ (the original “drawer” on the side).
                                          Note we’ve wrapped it in “hidden lg:block” so it doesn’t show on mobile.
                                        */}
                                        <div className="hidden lg:block">
                                            {isStatsOpen && <div
                                                className={`absolute -right-7 w-48 h-full transition-transform duration-300 z-0 ${isStatsOpen ? 'translate-x-full' : 'translate-x-0'
                                                    }`}
                                            >
                                                {renderStatsContent()}
                                            </div>
                                            }
                                            {/* Compression stats toggle arrow (desktop) */}
                                            <div
                                                className={`absolute top-3 -right-2 w-6 h-6 translate-x-full cursor-pointer`}
                                                onClick={() => setIsStatsOpen(!isStatsOpen)}
                                            >
                                                {isStatsOpen ? (
                                                    <LeftArrow className="fill-current text-gray-400 hover:text-gray-600 cursor-pointer" />
                                                ) : (
                                                    <RightArrow className="fill-current text-gray-400 hover:text-gray-600 cursor-pointer" />
                                                )}
                                            </div>
                                        </div>


                                        {(loading.sqwish ||
                                            (isSwitchOn && loading.sqwish_reponse)) && (
                                                <div className="self-end pr-6 lg:pr-0 lg:self-center absolute top-[26px]">
                                                    <div className="dot-typing-loader"></div>
                                                </div>
                                            )}
                                        <div className="p-2 text-4xl font-bold">
                                            <a
                                                href="/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <img
                                                    src={sqwishLogo}
                                                    alt="Sqwish Logo"
                                                    className="h-10 w-auto"
                                                />
                                            </a>
                                        </div>
                                        <div className="w-full h-full flex flex-col">
                                            {/* Compressed Prompt */}
                                            {sqwishText && sqwishTextStats && (
                                                <div
                                                    ref={compressedPromptRef}
                                                    className="w-full flex flex-col"
                                                >
                                                    <ChatBubble
                                                        key={isSwitchOn ? 'on' : 'off'}
                                                        maxHeight={isSwitchOn ? '20vh' : '50vh'}
                                                        text={sqwishText}
                                                        role="sqwish"
                                                        targetRGBA="rgba(255, 243, 215, 1)"
                                                        stats={format_sqwish_text_stats(sqwishTextStats)}
                                                    />
                                                </div>
                                            )}

                                            {!(
                                                sqwishText &&
                                                sqwishTextStats
                                            ) && (
                                                    <div className="w-full self-end flex flex-col h-[20vh] items-center justify-center mt-4 min-w-[280px]">
                                                        <div className="italic">You are in a short queue…</div>
                                                    </div>
                                                )}

                                            {/* LLM response to the compressed text (streaming or final) */}
                                            {isSwitchOn && (
                                                <div className="absolute w-full flex flex-col bottom-0">
                                                    {(streamPartialSqwish || streamingSqwishInProgress) ? (
                                                        <ChatBubble
                                                            maxHeight="45vh"
                                                            text={streamPartialSqwish}
                                                            role={INFERENCE_MODEL}
                                                            targetRGBA="rgba(255, 226, 155, 1)"
                                                            stats={
                                                                streamSqwishStats
                                                                    ? format_response_stats({
                                                                        throughput: streamSqwishStats.throughput,
                                                                        TTFT: streamSqwishStats.TTFT,
                                                                        total_latency: streamSqwishStats.total_latency,
                                                                    })
                                                                    : undefined
                                                            }
                                                        />
                                                    ) : (
                                                        sqwishResponse &&
                                                        sqwishResponseStats && (
                                                            <ChatBubble
                                                                maxHeight="45vh"
                                                                text={sqwishResponse}
                                                                role={INFERENCE_MODEL}
                                                                targetRGBA="rgba(255, 226, 155, 1)"
                                                                stats={format_response_stats(
                                                                    sqwishResponseStats
                                                                )}
                                                            />
                                                        )
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    {!isStatsOpen && (
                                        <div className="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2">
                                            <CSSTransition
                                                in={!!(originalText && sqwishText)}
                                                nodeRef={nodeRef}
                                                timeout={300}
                                                classNames="fade"
                                                unmountOnExit
                                            >
                                                <div
                                                    ref={nodeRef}
                                                    className="w-56 h-16 rounded-[20px] bg-[#bfe1a0] border-2 border-black flex flex-col items-center justify-center mt-12 lg:mt-0"
                                                >
                                                    <div className="w-full flex flex-row">
                                                        <div className="w-32 flex items-center justify-center text-4xl font-bold ml-3">
                                                            {calculateTokensCompressionPercent() + '%'}
                                                        </div>
                                                        <div className="w-28 flex flex-col justify-center items-center text-center text-xs">
                                                            <span>input tokens</span>
                                                            <span>removed</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CSSTransition>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/*
                              MOBILE STATS (block on < lg screens).
                              This goes AFTER the two bubbles (stacked vertically),
                              pinned at the bottom with a toggle arrow (vertical).
                            */}
                            <div className={`block lg:hidden w-full relative  ${isStatsOpen ? "" : "mt-3"}`}>
                                {/* Toggle Arrow at the top (or bottom) of the stats drawer */}
                                <div className={`flex justify-center cursor-pointer ${isStatsOpen ? "" : "mb-6"}`} >
                                    {isStatsOpen ? (
                                        <div onClick={() => setIsStatsOpen(!isStatsOpen)}>
                                            <UpArrow className="fill-current text-gray-400 hover:text-gray-600 cursor-pointer" />
                                        </div>
                                    ) : (
                                        <div onClick={() => setIsStatsOpen(!isStatsOpen)}>
                                            <DownArrow className="fill-current text-gray-400 hover:text-gray-600 cursor-pointer" />
                                        </div>
                                    )}
                                </div>

                                {/* The stats drawer itself */}
                                <div
                                    className={`overflow-hidden transition-all duration-300 ${isStatsOpen ? 'max-h-[500px]' : 'max-h-0'
                                        } rounded-xl shadow-md`}
                                >

                                    {isStatsOpen && (
                                        <div className={`absolute flex bottom-0 justify-center w-full h-full transition-transform duration-300 z-0 ${isStatsOpen ? 'translate-y-full' : 'translate-y-0'
                                            }`}>
                                            <div className="w-48">
                                                {renderStatsContent()}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {/* Accept Terms overlay */}
            {
                !hasAcceptedTerms && (
                    <div className="absolute inset-0 flex items-center justify-center z-50">
                        <AcceptModal onAccept={handleAccept} />
                    </div>
                )
            }
        </div>
    );
};

// SVG arrows remain the same; you only add new ones for Up/Down (for mobile).
// You can keep these two for desktop:
const LeftArrow = ({ className }: { className: string }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            height="20"
            width="20"
            viewBox="0 0 492.004 492.004"
        >
            <g>
                <g>
                    <path
                        d="M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12
                            c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028
                            c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265
                            c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z"
                        transform="rotate(180 246.002 246.002)"
                    />
                </g>
            </g>
        </svg>
    );
};

const RightArrow = ({ className }: { className: string }) => {
    return (
        <svg
            className={className}
            height="20"
            width="20"
            viewBox="0 0 492.004 492.004"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g>
                <g>
                    <path
                        d="M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12
                            c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028
                            c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265
                            c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z"
                    />
                </g>
            </g>
        </svg>
    );
};

// Below are new up/down arrows for mobile. Feel free to style them similarly.
const UpArrow = ({ className }: { className: string }) => {
    return (
        <svg
            className={className}
            height="20"
            width="20"
            viewBox="0 0 492 492"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M464.344,313.188L278.636,128.489c-12.686-12.686-33.27-12.686-45.957,0L27.975,333.188
                 c-12.497,12.497-12.497,32.761,0,45.258l2.808,2.808c12.497,12.497,32.761,12.497,45.258,0L255.16,231.604
                 l173.126,173.65c12.497,12.497,32.761,12.497,45.258,0l2.808-2.808C476.841,345.949,476.841,325.685,464.344,313.188z"
            />
        </svg>
    );
};

const DownArrow = ({ className }: { className: string }) => {
    return (
        <svg
            className={className}
            height="20"
            width="20"
            viewBox="0 0 492 492"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M27.656,178.812l185.708,184.699c12.686,12.686,33.27,12.686,45.957,0L464.025,158.812
                 c12.497-12.497,12.497-32.761,0-45.258l-2.808-2.808c-12.497-12.497-32.761-12.497-45.258,0L236.84,260.396L63.714,86.746
                 c-12.497-12.497-32.761-12.497-45.258,0l-2.808,2.808C15.159,146.851,15.159,166.115,27.656,178.812z"
            />
        </svg>
    );
};

// A small stat card component. You can customize the style or logic as needed.
interface StatCardProps {
    title: string;
    value: number | null;
    arrow?: 'up' | 'down' | null;
    suffix?: string;
    percentage?: string | null;
    isVisible?: boolean;
}

const StatCard: React.FC<StatCardProps> = ({
    title,
    value,
    arrow,
    suffix,
    percentage,
    isVisible = true,
}) => {
    if (!isVisible || value === null) {
        return null;
    }

    // Decide background color: green if positive, red if negative
    let bgColor = value >= 0 ? 'bg-[#cef0c4]' : 'bg-[#f6d4d4]';

    // If arrow was "down" meaning "good if down", then if the value is negative, flip to "up", etc.
    let displayArrow = arrow;
    if (arrow === 'down') {
        displayArrow = Math.round(value) > 0 ? 'down' : 'up';
    } else if (arrow === 'up') {
        displayArrow = Math.round(value) > 0 ? 'up' : 'down';
    }
    if (Math.round(value) === 0) {
        arrow = undefined;
        bgColor = "bg-transparent";
    }
    return (
        <div className={`w-full p-3 rounded-[20px] shadow-lg ${bgColor}`}>
            <div className="text-sm text-gray-700 font-bold mb-1 px-2">{title}</div>
            <div className="flex flex-row items-baseline gap-1 justify-center">
                {arrow !== undefined && (
                    <span style={{ color: '#fa780b' }} className="heading-4-5 mr-1">
                        {displayArrow === 'down' ? '▼' : '▲'}
                    </span>
                )}
                {percentage ? (
                    <>
                        <span className="text-5xl font-bold text-gray-800">
                            {percentage}
                        </span>
                        <span className="text-3xl text-gray-600">%</span>
                    </>
                ) : (
                    <>
                        <span className="text-2xl font-bold text-gray-800">
                            {Math.round(Math.abs(value))}
                        </span>
                        {suffix && <span className="text-base text-gray-600">{suffix}</span>}
                    </>
                )}
            </div>
        </div>
    );
};

export default Playground;

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Styles
import './styles/main.css';
import './styles/highlighter-theme.css';
import './styles/preview-stack.css';
import './styles/stack.css';
import './styles/tailwind.css';
import './styles/scrolling.css';
// Components

import Playground from './plaground/Playground'; // Import the Playground component
import HomePage from './homepage/HomePage';
import RequestAccess from './access/RequestAccess';
import Header from './Header';

const App: React.FC = () => {
  return (
    <Router>
      <Header />
      <main role="main" style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/playground" element={<Playground />} />
          <Route path="/access" element={<RequestAccess />} />
        </Routes>
      </main>
    </Router>
  );
};

export default App;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}

.animate-scroll {
  display: flex;
  white-space: nowrap;
  animation: scroll 20s linear infinite;
}

.animate-scroll img {
  flex-shrink: 0;
}`, "",{"version":3,"sources":["webpack://./src/styles/scrolling.css"],"names":[],"mappings":"AAAA;EACE;IACE,wBAAwB;EAC1B;;EAEA;IACE,2BAA2B;EAC7B;AACF;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,qCAAqC;AACvC;;AAEA;EACE,cAAc;AAChB","sourcesContent":["@keyframes scroll {\n  0% {\n    transform: translateX(0);\n  }\n\n  100% {\n    transform: translateX(-50%);\n  }\n}\n\n.animate-scroll {\n  display: flex;\n  white-space: nowrap;\n  animation: scroll 20s linear infinite;\n}\n\n.animate-scroll img {\n  flex-shrink: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

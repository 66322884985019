import React from 'react';
import FAQItem from './FAQItem';
import octopusReading from '../../assets/octopus/octopus-reading.png';

interface FAQItemType {
  question: string;
  answer: string;
}

const FAQSection: React.FC = () => {
  const faqItems: FAQItemType[] = [
    {
      question: "How does Sqwish ensure data privacy and security?",
      answer: "We maintain the best practices involving security of our services, data storage and retrieval. All your data is encrypted in transit and at rest. If you're on a paid tier, we do not train our models on your data. For enterprises, we offer managed hosting to deploy Sqwish on private clouds. If you need to talk about these options, feel free to drop us an email at contact@sqwish.ai."
    },
    {
      question: "Can Sqwish handle multilingual prompts?",
      answer: "Yes, Sqwish supports over 10 languages, including English, Mandarin, Spanish, Arabic, Japanese, German, French, Portuguese, Hindi, and Russian."
    },
    {
      question: "How often are the Sqwish models updated?",
      answer: "We continuously improve our models, with major updates released on a monthly cycle. These updates are focused on ensuring compatibility with newly released models, fixing bugs or model misbehaviour reported by users and overall performance. Older versions of the model are still available through the API to ensure a smooth transition."
    },
    {
      question: "I am using a fine-tuned open source model, will Sqwish work for me?",
      answer: "Yes! We support models in the Llama family currently, but will expand support to more models in the coming months."
    },
    {
      question: "How does Sqwish maintain response quality while compressing prompts?",
      answer: "Sqwish preserves response quality through: specialized reward modeling for nearly lossless compression, training on diverse synthetic datasets and continuous benchmarking against uncompressed baselines. We publish our benchmarks for transparency. Check our docs for detailed metrics."
    }
  ];

  return (
    <section className="section bg-blue pt-8 px-4 pb-8 lg:pb-24 gap-8 lg:gap-16 lg:pt-16 lg:px-16 flex flex-col -mt-8 lg:-mt-16 items-center rounded-t-3xl lg:rounded-t-[2.5rem] relative">
      <div className="flex flex-col pt-8 px-4 pb-24 gap-8 lg:gap-16 lg:pt-16 lg:px-16 w-full mx-auto lg:flex-row items-start max-w-7xl">
        <div className='relative flex flex-row'>
          <div className="w-full max-w-[50rem] mx-auto flex flex-col items-start text-start gap-6">
            <h3 className="text-black s-body">FAQ</h3>
            <h2 className="text-black heading-2 w-full max-w-[21rem]">Questions? Answers.</h2>
            <p className="l-body w-full max-w-[21rem]">Your top questions.</p>
          </div>
          <div className="absolute top-0 -translate-y-1/4">
            <img
              src={octopusReading}
              alt="octopus-reading"
              className="w-[20%] ml-60 mt-28 lg:mt-0 lg:ml-14 h-auto"
            />
          </div>
        </div>

        <div className='w-full'>
          <div className="flex flex-col gap-4 w-full max-w-[32rem] transition ease-out delay-[300ms] ml-auto">
            {faqItems.map((item, index) => (
              <FAQItem key={`collapsible-${index}`} question={item.question} answer={item.answer} id={`collapsible-${index}`} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
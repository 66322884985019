import React from 'react';
import CostCalculator from './CostCalculator';

const PromptCompressionFeatures: React.FC = () => {
  return (
    <div className="flex flex-col lg:flex-row lg:pt-8 pt-8 w-full h-full gap-4 items-center lg:gap-8 mx-auto justify-center">
      <div className="px-6 bg-soft-pink rounded-2xl py-4 h-[9rem] w-2/3 lg:w-1/3 max-w-[25rem] xl:px-12 lg:h-[11rem] flex flex-col justify-center">
        <div className="flex lg:w-full flex-row gap-2 items-center lg:gap-4 max-w-7xl justify-center px-2">
          <div className="flex flex-col justify-center">
            <div>
              <h3 className="heading-4">Up</h3>
            </div>
            <div>
              <h3 className="heading-4">to</h3>
            </div>
          </div>
          <div className="flex items-left">
            <h3 className="heading-1-tight">75%</h3>
          </div>
        </div>
        <h3 className="flex items-center justify-center heading-4-5 px-2">Input token reduction</h3>
      </div>
      <div className="px-6 bg-soft-pink rounded-2xl py-4 h-[9rem] w-2/3 lg:w-1/3 max-w-[25rem] xl:px-12 lg:h-[11rem] flex flex-col justify-center ">
        <div className="flex lg:w-full flex-row gap-2 items-center lg:gap-4 max-w-7xl justify-center px-2">
          <h3 className="flex items-center heading-2-5 mb-1">&lt;</h3>
          <div className="flex items-left">
            <h3 className="heading-1-tight">200ms</h3>
          </div>
        </div>
        <h3 className="flex items-center justify-center heading-4-5 px-2">Sqwish total latency</h3>
      </div>
      <div className="relative px-6 bg-soft-pink rounded-2xl py-4 h-[9rem] w-2/3 lg:w-1/3 max-w-[25rem] xl:px-12 lg:h-[11rem] flex flex-col justify-center ">
        <div className="flex lg:w-full flex-row gap-2 items-center lg:gap-4 max-w-7xl justify-center px-2">
          {/* <h3 className="flex items-center heading-2-5 mb-1">&lt;</h3> */}
          <div className="flex items-left">
            <h3 className="heading-1-tight">250k</h3>
            <div className="ml-1.5 flex flex-col justify-center pb-1.5">
              <div>
                <h3 className="heading-5">tokens/</h3>
              </div>
              <div>
                <h3 className="heading-5">second</h3>
              </div>
            </div>
          </div>
        </div>
        <h3 className="flex items-center justify-center heading-4-5 px-2">compression speed*</h3>
        <div className="absolute text-[14px] bottom-0 right-0 translate-y-full">
          *for 50k tokens input
        </div>
      </div>
    </div>
  );
};


const CostsSection: React.FC = () => {
  return (
    <section className="bg-pink pt-4 lg:pt-20 px-4 pb-24 lg:pb-44 gap-8 lg:gap-16 lg:px-16 -mt-8 lg:-mt-16 items-center rounded-t-3xl lg:rounded-t-[2.5rem] relative">
      <div className="flex flex-col lg:flex-row items-stretch justify-center max-w-[1580px] mx-auto h-full">
        <div className="w-full lg:w-1/2 xlg:w-1/3 flex">
          <div className="w-full flex items-center justify-center">
            <div className="max-w-3xl w-full mx-auto flex flex-col items-center text-center py-8 lg:py-0">
              <div className="w-full max-w-[50rem] mx-auto flex flex-col items-center text-center gap-6">
                <h3 className="text-black s-body">ESTIMATE YOUR SAVINGS</h3>
                <h2 className="text-black heading-2 w-full max-w-[45rem] px-4">Unmatched efficiency</h2>
                <p className="text-black l-body w-full max-w-[36rem]">Use our interactive calculator to see how Sqwish can optimize your AI workflows. Input your average prompt length, model, and monthly request volume.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/2 xlg:w-2/3 flex items-center justify-center py-8 lg:py-0 md:px-4 lg:px-2">
          <div className="w-full h-full max-w-[600px] max-h-[600px] lg:max-w-[800px] lg:max-h-[800px] flex items-center justify-center p-4">
            <CostCalculator />
          </div>
        </div>
      </div>
      <div className="w-full max-w-[1100px] mx-auto flex flex-col items-center text-center gap-6 mt-6">
        <PromptCompressionFeatures />
      </div>
    </section>
  );
};

export default CostsSection;

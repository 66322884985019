import React, { useState, useEffect, ChangeEvent, useCallback } from 'react';
import tryItHorizontal from '../../assets/icons/try-it-horizontal.png';
import tryItVertical from '../../assets/icons/try-it-vertical.png';
import './CostCalculator.css';

type ModelName = "gpt4o" | "gpt4o-mini";

interface ModelOption {
  name: ModelName;
  costPerToken: number;
}

const models: ModelOption[] = [
  { name: 'gpt4o', costPerToken: 5 / 1e6 },
  { name: 'gpt4o-mini', costPerToken: 0.15 / 1e6 },
];

const ttftValuesDict: Record<ModelName, number[]> = {
  'gpt4o': [0.35, 0.37, 0.39, 0.55, 0.80, 1.80, 3.59, 8.42],
  'gpt4o-mini': [0.37, 0.38, 0.46, 0.54, 0.79, 1.77, 3.81, 9.96]
};

const tpsValuesDict: Record<ModelName, number[]> = {
  'gpt4o': [95.64, 95.14, 94.89, 93.11, 92.11, 84.46, 78.07, 77.26],
  'gpt4o-mini': [128.12, 125.12, 125.06, 121.11, 105.10, 82.28, 55.97, 38.59]
};
const tokenLengths = [100, 500, 1000, 5000, 10000, 25000, 50000, 100000];

if (
  Object.values(ttftValuesDict).some(arr => arr.length !== tokenLengths.length) ||
  Object.values(tpsValuesDict).some(arr => arr.length !== tokenLengths.length)
) {
  throw new Error('The lengths of ttftValuesDict, tpsValuesDict, and tokenLengths must be equal');
}

const sqwishLatency = 0.2;
const sqwishCostPerToken = 0.00 / 1e6;

const compressionOptions = ['light', 'moderate', 'strong'];
const compressionRates = [0.25, 0.5, 0.67]; // light, moderate, strong

const formatPercentage = (value: number, digits: number = 1): string => {
  return value.toFixed(digits);
};

const CostCalculator: React.FC = () => {
  const [promptLength, setPromptLength] = useState<number>(50000);
  const [model, setModel] = useState<ModelOption>(models[1]);
  const [monthlyRequests, setMonthlyRequests] = useState<number>(1000000);
  const [compressionPreference, setCompressionPreference] = useState<number>(2);
  const [annualSavings, setAnnualSavings] = useState<number>(0);
  const [savingsPercentage, setSavingsPercentage] = useState<number>(0);
  const [showMaxPromptLengthWarning, setShowMaxPromptLengthWarning] = useState<boolean>(false);

  // New state variables for the additional metrics
  const [ttftLatencyReduction, setTtftLatencyReduction] = useState<number>(0);
  const [ttftLatencyReductionPercentage, setTtftLatencyReductionPercentage] = useState<number>(0);
  const [throughputImprovement, setThroughputImprovement] = useState<number>(0);
  const [throughputImprovementPercentage, setThroughputImprovementPercentage] = useState<number>(0);

  const calculateSavings = useCallback((): void => {
    // Calculation for input costs
    const compressionRate = compressionRates[compressionPreference];
    const modelCostBefore = promptLength * model.costPerToken * monthlyRequests * 12;
    const modelCostAfter = modelCostBefore * (1 - compressionRate);
    const sqwishCost = 12 * (promptLength * monthlyRequests) * sqwishCostPerToken;
    // const sqwishCost = 12 * (promptLength + (promptLength * (1 - compressionRate)) * monthlyRequests) * sqwishCostPerToken;

    const totalSavings = modelCostBefore - modelCostAfter - sqwishCost;
    setAnnualSavings(totalSavings);
    setSavingsPercentage((totalSavings / modelCostBefore) * 100);

    // Calculation for ttft and throughput
    const ttftValues = ttftValuesDict[model.name];
    const tpsValues = tpsValuesDict[model.name];

    const interpolate = (value: number, x1: number, y1: number, x2: number, y2: number) => y1 + (value - x1) * (y2 - y1) / (x2 - x1);

    const getInterpolatedPerformance = (tokens: number): [number, number] => {
      if (tokens < tokenLengths[0]) return [ttftValues[0], tpsValues[0]];
      if (tokens > tokenLengths[tokenLengths.length - 1]) return [ttftValues[tokenLengths.length - 1], tpsValues[tokenLengths.length - 1]];
      for (let i = 0; i < tokenLengths.length - 1; i++) {
        if (tokenLengths[i] <= tokens && tokens <= tokenLengths[i + 1]) {
          const ttft = interpolate(tokens, tokenLengths[i], ttftValues[i], tokenLengths[i + 1], ttftValues[i + 1]);
          const tps = interpolate(tokens, tokenLengths[i], tpsValues[i], tokenLengths[i + 1], tpsValues[i + 1]);
          return [ttft, tps];
        }
      }
      return [0, 0]; // Fallback case
    };

    const [ttftBefore, tpsBefore] = getInterpolatedPerformance(promptLength);
    const [ttftAfter, tpsAfter] = getInterpolatedPerformance(promptLength * (1 - compressionRate));

    const ttftBeforeWithLatency = ttftBefore;
    const ttftAfterWithLatency = ttftAfter + sqwishLatency;

    const ttftImprovement = ttftBeforeWithLatency - ttftAfterWithLatency;
    const ttftPercentageImprovement = (ttftImprovement / ttftBeforeWithLatency) * 100;

    const tpsImprovement = tpsAfter - tpsBefore;
    const tpsPercentageImprovement = (tpsImprovement / tpsBefore) * 100;

    setTtftLatencyReduction(ttftImprovement * 1000); // Convert to milliseconds
    setTtftLatencyReductionPercentage(ttftPercentageImprovement);
    setThroughputImprovement(tpsImprovement);
    setThroughputImprovementPercentage(tpsPercentageImprovement);
  }, [promptLength, model, monthlyRequests, compressionPreference]);

  useEffect(() => {
    calculateSavings();
  }, [calculateSavings]);

  const formatNumber = (num: number, digits: number = 1): string => {
    if (isNaN(num) || num === 0) return '0';
    if (num < 1) return num.toFixed(digits); // Ensure only one decimal place for numbers < 1
    const suffixes = ['', 'k', 'M', 'B', 'T'];
    const suffixNum = Math.min(4, Math.floor(Math.log10(Math.abs(num)) / 3));
    const shortNum = (num / Math.pow(1000, suffixNum)).toFixed(digits);
    return `${shortNum}${suffixes[suffixNum]}`;
  };

  const handleInputChange = (setter: React.Dispatch<React.SetStateAction<number>>, isPromptLength: boolean = false) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = Number(e.target.value);
      if (isPromptLength && value > 100000) {
        setter(100000);
        setShowMaxPromptLengthWarning(true);
      } else {
        setter(value);
        setShowMaxPromptLengthWarning(false);
      }
    };

  const getColorClass = (value: number) => value >= 0 ? 'bg-green-300' : 'bg-red-300';

  return (
    <div className="w-full max-w-xl mx-auto relative">
      <div className="absolute right-0 top-full transform -translate-y-full translate-x-4 origin-right rotate-90">
        <h2 className="text-[22px] font-bold text-gray-800 whitespace-nowrap">SAVINGS CALCULATOR</h2>
      </div>
      <div className="flex flex-col justify-between">

        {window.innerWidth < 1024 ? <img
          src={tryItVertical}
          alt="octopus-exploring"
          className="w-[135px] h-auto translate-y-[30%] -translate-x-[20%] "
        /> : null}
        <div className="relative bg-soft-pink text-white font-sans p-5 rounded-lg flex flex-col lg:flex-row justify-between">
          <img
            src={tryItHorizontal}
            alt="octopus-exploring"
            className="absolute top-0 left-0 h-auto w-[95px] ml-2 pr-2 -translate-y-1/2 -translate-x-full invisible lg:visible"
          />
          <div className="w-full lg:w-[44%] space-y-6">
            <div className="flex flex-col">
              <div className="text-xs text-gray-800 mb-1 uppercase">PROMPT LENGTH (TOKENS)</div>
              <div className="bg-soft-soft-pink rounded p-2 relative">
                <select
                  value={promptLength}
                  onChange={(e) => {
                    setPromptLength(Number(e.target.value));
                    setShowMaxPromptLengthWarning(false);
                  }}
                  className="w-full bg-transparent text-black text-[15px] outline-none appearance-none pr-8"
                >
                  {[1000, 5000, 10000, 50000, 100000].map((value) => (
                    <option key={value} value={value}>{value.toLocaleString('en-US')}</option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
            <div>
              <div className="text-xs text-gray-800 mb-1 uppercase">MODEL</div>
              <div className="bg-soft-soft-pink rounded p-2 relative">
                <select
                  value={model.name}
                  onChange={(e) => {
                    setModel(models.find(m => m.name === e.target.value) || models[0]);
                    setShowMaxPromptLengthWarning(false);
                  }}
                  className="w-full bg-transparent text-black text-[15px] outline-none appearance-none pr-8"
                >
                  {models.map((m) => (
                    <option key={m.name} value={m.name}>{m.name}</option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
            <InputField
              label="NUMBER OF MONTHLY REQUESTS"
              value={Number(monthlyRequests).toLocaleString('en-US')}
              onChange={handleInputChange(setMonthlyRequests)}
            />
            <div className="w-full">
              <div className="text-xs text-gray-800 mb-1 uppercase">COMPRESSION PREFERENCE</div>
              <div className="relative pt-5 -ml-3">
                <div className="flex justify-center">
                  <div className="relative w-2/3">
                    <input
                      type="range"
                      min="0"
                      max="2"
                      step="1"
                      value={compressionPreference}
                      onChange={handleInputChange(setCompressionPreference)}
                      className="slider-input w-full bg-[#4a4a4a] appearance-none h-1 rounded outline-none opacity-70 transition-opacity duration-200"
                    />
                    <div className="absolute top-0 left-0 w-full h-full pointer-events-none flex justify-between items-center px-[5px] pt-[6px]">
                      <div className="w-0.5 h-3 bg-[#80717e]"></div>
                      <div className="w-0.5 h-3 bg-[#80717e]"></div>
                      <div className="w-0.5 h-3 bg-[#80717e]"></div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center">
                  <div className="flex justify-between mt-0 w-5/6 ">
                    {compressionOptions.map((option, index) => (
                      <div key={option} className="w-1/3 text-center">
                        <span className={`text-xs inline-block ${index === compressionPreference ? 'text-gray-800 font-bold' : 'text-gray-400'}`}>
                          {option}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-1/2 mt-6 lg:mt-0 lg:ml-4 flex flex-col justify-center items-center gap-2 w-full lg:w-[66%]">
            {/* <div className="w-full p-5 rounded text-center max-w-[250px]"> */}
            <div className='min-w-[276px] px-3'>
              <div className="text-sm text-gray-800 uppercase">INPUT SAVINGS (ANNUAL)</div>
              <div className={`w-full p-2 rounded-lg text-center max-w-[250px] ${getColorClass(annualSavings)}`}>
                <div className="flex flex-row justify-center items-center gap-2">
                  <div className="w-full flex justify-center items-center">
                    <span style={{ color: '#fa780b' }} className="heading-4-5 mr-1"> {annualSavings > 0 ? '▼' : '▲'} </span>
                    <div className="text-2xl font-bold truncate-early text-gray-700 self-end">{`$`}</div>
                    <div className="text-3xl font-bold text-gray-700">{formatNumber(annualSavings)}</div>
                  </div>
                  <div className="flex h-full justify-center items-center gap-2">
                    <div className={`text-[14px] leading-[16px] font-bold text-gray-600`}>
                      ({formatPercentage(savingsPercentage, 1)}%)
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='min-w-[276px] px-3'>
              <div className="text-sm text-gray-800 uppercase mt-2">TTFT REDUCTION (NET)</div>
              <div className={`w-full p-2 rounded-lg text-center max-w-[250px] ${getColorClass(ttftLatencyReduction)}`}>
                <div className="flex flex-row justify-center items-center gap-1">
                  <div className="w-full flex justify-center items-center gap-1">
                    <span style={{ color: '#fa780b' }} className="heading-4-5 mr-1"> {ttftLatencyReduction > 0 ? '▼' : '▲'} </span>
                    <div className="text-3xl font-bold truncate-early text-gray-700">{ttftLatencyReduction.toFixed(0)}</div>
                    <div className="text-xl font-bold text-gray-700 self-end">{`ms`}</div>
                  </div>
                  <div className="flex h-full justify-center items-center gap-2">
                    <div className={`text-[14px] leading-[16px] font-bold text-gray-600`}>
                      ({formatPercentage(ttftLatencyReductionPercentage, 1)}%)
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='min-w-[276px] px-3'>
              <div className="text-sm text-gray-800 uppercase mt-2">THROUGHPUT GAIN</div>
              <div className={`w-full p-2 rounded-lg text-center max-w-[250px] ${getColorClass(throughputImprovement)}`}>
                <div className="flex flex-row justify-center items-center gap-2">
                  <div className="w-full flex flex-row justify-center items-center gap-1">
                    <span style={{ color: '#fa780b' }} className="heading-4-5 mr-1"> {throughputImprovement > 0 ? '▲' : '▼'} </span>
                    <div className="text-3xl font-bold truncate-early text-gray-700">{formatNumber(throughputImprovement, 0)}</div>
                    <div className="text-xl font-bold text-gray-700 self-end">{`t/s`}</div>

                    {/* <div className='flex flex-col'>
                    <div className='text-[10px] leading-[10px] font-bold text-gray-700 self-end'>{`tokens/`}</div>
                    <div className="text-[10px] leading-[10px] font-bold text-gray-700 self-end">{`second`}</div>
                  </div> */}
                  </div>
                  <div className="flex h-full justify-center items-center gap-2">
                    <div className={`text-[14px] leading-[16px] font-bold text-gray-600`}>
                      ({formatPercentage(throughputImprovementPercentage, 1)}%)
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

const InputField: React.FC<{
  label: string;
  value: string | number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  showWarning?: boolean;
}> = ({ label, value, onChange, className, showWarning }) => (
  <div className="relative">
    <div className="text-xs text-gray-800 mb-1 uppercase">{label}</div>
    <div className={`bg-soft-soft-pink rounded p-2 ${className || ''}`}>
      <input
        type="text"
        value={value}
        onChange={(e) => {
          const numericValue = Number(e.target.value.replace(/,/g, ''));
          onChange({ ...e, target: { ...e.target, value: numericValue.toString() } });
        }}
        className="w-full bg-transparent text-black text-[15px] outline-none"
      />
    </div>
    {showWarning && (
      <div className="absolute -bottom-[17px] right-0 text-red-500 text-xs text-right mt-1">*allowed up to 100000</div>
    )}
  </div>
);

export default CostCalculator;
// frontend/src/api.ts

export const llm_inference = async (input_text: string, model: string): Promise<{ response: string, throughput: number, TTFT: number, total_latency: number, number_tokens: number }> => {
    const response = await fetch('/api/v1/llm_inference', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ input_text, model })
    });

    if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
};

export const sqwish = async (input_text: string): Promise<{ response: string, number_tokens: number, total_latency: number, throughput: number }> => {
    const response = await fetch('/api/v1/sqwish', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ input_text })
    });

    if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
};

export const tokenize = async (input_text: string): Promise<{ tokens_count: number }> => {
    const response = await fetch('/api/v1/tokenize', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ input_text })
    });

    if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
};

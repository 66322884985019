import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import userImage from "./images/user.png";
import sqwishImage from "./images/sqwish.png";
import metaImage from "./images/meta.png";
import openAIImage from "./images/openai.png";

interface TextProps {
  text: string;
  role: "user" | "sqwish" | "llama3-11b" | "gpt4o" | "gpt4o-mini";
  maxHeight: string;
  stats: React.ReactNode;
  targetRGBA?: string;
  marginTop?: number;
}

const RoleNames = {
  "llama3-11b": "meta-llama/Llama-3.2-11B-Vision-Instruct-Turbo",
  "sqwish": "User (compressed via Sqwish)",
  "user": "User",
  "gpt4o": "GPT-4o",
  "gpt4o-mini": "GPT-4o mini"
}

const RoleImages = {
  "llama3-11b": [{ image: metaImage, zoom: 70, borderColor: "rgba(0, 0, 0, 0.3)" }],
  "sqwish": [{ image: userImage, zoom: 80, borderColor: "rgba(0, 0, 0, 0.3)" }, { image: sqwishImage, zoom: 75, borderColor: "rgba(0, 0, 0, 0.3)" }],
  "user": [{ image: userImage, zoom: 80, borderColor: "rgba(0, 0, 0, 0.3)" }],
  "gpt4o": [{ image: openAIImage, zoom: 65, borderColor: "rgba(0, 0, 0, 0.3)" }],
  "gpt4o-mini": [{ image: openAIImage, zoom: 65, borderColor: "rgba(0, 0, 0, 0.3)" }]
}

// const parseTextToBold = (text: string | null) => {
//   if (!text) return null;
//   const parts = text.split(/(\*\*[^*]+\*\*)/g);
//   return parts.map((part, index) => {
//     if (part.startsWith('**') && part.endsWith('**')) {
//       return <strong key={index}>{part.slice(2, -2)}</strong>;
//     }
//     return part;
//   });
// };


const parseTextToBold = (text: string | null): React.ReactNode[] | null => {
  if (!text) return null;

  const lines = text.split('\n');
  let elements: React.ReactNode[] = [];
  let listItems: React.ReactNode[] = [];
  let subListItems: React.ReactNode[] = [];
  let inList = false;
  let inSubList = false;

  const closeSubList = () => {
    if (inSubList) {
      listItems.push(<ul key={`sub-ul-${listItems.length}`} className="list-[circle] pl-4 mt-1">{subListItems}</ul>);
      subListItems = [];
      inSubList = false;
    }
  };

  const closeList = () => {
    if (inList) {
      closeSubList();
      elements.push(<ul key={`ul-${elements.length}`} className="list-disc pl-5 my-2">{listItems}</ul>);
      listItems = [];
      inList = false;
    }
  };

  lines.forEach((line, index) => {
    if (line.trim() === '') {
      closeList();
      elements.push(<br key={`br-${index}`} />);
      return;
    }

    if (line.startsWith('* ')) {
      closeList();
      inList = true;
      const content = processLine(line.slice(2).trim());
      listItems.push(<li key={`li-${listItems.length}`} className="mb-1">{content}</li>);
    } else if (line.startsWith('\t+ ') || line.startsWith('    + ')) {
      inSubList = true;
      const content = processLine(line.replace(/^\s*\+ /, '').trim());
      subListItems.push(<li key={`sub-li-${subListItems.length}`} className="mb-1">{content}</li>);
    } else {
      closeList();
      const content = processLine(line.trim());
      elements.push(<p key={`p-${elements.length}`} className="mb-2">{content}</p>);
    }
  });

  closeList();

  return elements;
};


const processLine = (line: string): React.ReactNode[] => {
  const parts = line.split(/(\*\*.*?\*\*)/);
  return parts.map((part, index) => {
    if (part.startsWith('**') && part.endsWith('**')) {
      return <strong key={index}>{part.slice(2, -2)}</strong>;
    }
    return part;
  });
};

const GradientTop = ({ targetRGBA }: { targetRGBA: string }) => {
  return (
    <div className="absolute left-0 top-0 h-[45px] w-full pointer-events-none">
      <div
        className="w-full h-full rounded-t-[20px]"
        style={{
          background: `linear-gradient(to top, rgba(255,255,255,0), ${targetRGBA})`
        }}
      ></div>
    </div>
  );
};

const GradientBottom = ({ targetRGBA }: { targetRGBA: string }) => {
  return (
    <div className="absolute left-0 bottom-0 h-[45px] w-full pointer-events-none">
      <div
        className="w-full h-full rounded-b-[20px]"
        style={{
          background: `linear-gradient(to bottom, rgba(255,255,255,0), ${targetRGBA})`
        }}
      ></div>
    </div>
  );
};

const ChatBubble: React.FC<TextProps> = ({ text, role, maxHeight, targetRGBA, stats, marginTop }) => {
  const paragraphRef = useRef<HTMLDivElement | null>(null);
  const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);
  const [isScrolledToTop, setIsScrolledToTop] = useState(true);
  const outerBubbleRef = useRef<HTMLDivElement | null>(null);
  const [contentHeight, setContentHeight] = useState<string>("550px");
  const position = (role === "user" || role === "sqwish") ? "right" : "left";


  const handleScroll = () => {
    if (paragraphRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = paragraphRef.current;
      setIsScrolledToEnd(scrollTop + clientHeight + 5 >= scrollHeight);
      setIsScrolledToTop(scrollTop <= 0);
    }
  };

  const handleBubbleHeight = () => {
    if (outerBubbleRef.current) {
      const newHeight = `${outerBubbleRef.current.offsetHeight - 65}px`;
      setContentHeight(newHeight);
    }
  };

  useEffect(() => {
    const currentRef = paragraphRef.current;
    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
      handleScroll();
    }
    window.addEventListener('resize', handleScroll);
    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', handleScroll);
      }
      window.removeEventListener('resize', handleScroll);
    };
  }, [text]);

  useLayoutEffect(() => {
    handleBubbleHeight();
  }, [text]);

  useEffect(() => {
    handleBubbleHeight();
    window.addEventListener('resize', handleBubbleHeight);
    return () => {
      window.removeEventListener('resize', handleBubbleHeight);
    };
  }, []);

  return (
    <>

      <div className={`relative w-full flex flex-col`} ref={outerBubbleRef} style={{ height: maxHeight, minHeight: "150px" }}>
        <div className={`max-w-[80%] min-w-[280px] h-full flex flex-col ${position === "right" ? "self-end" : "self-start"}`}>
          <div className={`flex flex-row gap-1 text-xs mt-[0.3rem] text-gray-800  ${position === "right" ? "text-right flex-row-reverse -mr-4" : "text-left -ml-4"}`}>
            <div className="flex items-center">
              <div className="flex">
                {RoleImages[role].map((image, index) => (
                  <div
                    key={index}
                    className={`relative flex flex-col items-center justify-center h-4 w-4 rounded-full ${index !== 0 ? '-ml-2' : ''}`}
                    style={{
                      zIndex: index + 1,
                      marginLeft: index !== 0 ? '-0.5rem' : '0',
                    }}
                  >
                    <div
                      className="absolute inset-0 rounded-full"
                      style={{
                        backgroundColor: targetRGBA,
                        ...(image.borderColor ? {
                          border: `1px solid ${image.borderColor}`,
                          top: '-1px',
                          left: '-1px',
                          right: '-1px',
                          bottom: '-1px',
                        } : {})
                      }}
                    ></div>
                    <img
                      src={image.image}
                      alt={RoleNames[role]}
                      className="relative w-full h-full object-cover rounded-full"
                      style={{ transform: `scale(${image.zoom / 100})` }}
                    />
                  </div>
                ))}
              </div>
            </div>
            {RoleNames[role]}
          </div>
          < div className={`relative chat-bubble max-h-[100%]`}>
            <div className={`px-[1rem] py-[0.5rem] ${position === "right" ? "rounded-l-[20px] rounded-br-[20px]" : "rounded-r-[20px] rounded-bl-[20px]"} `} style={{ backgroundColor: targetRGBA }}>
              {
                targetRGBA && !isScrolledToTop && (
                  <GradientTop targetRGBA={targetRGBA} />
                )
              }
              <div ref={paragraphRef} className={`overflow-y-auto chat-content`} style={{ maxHeight: contentHeight }}>
                {parseTextToBold(text)?.map((element, index) => (
                  <React.Fragment key={index}>{element}</React.Fragment>
                ))}
              </div>
              {
                targetRGBA && !isScrolledToEnd && (
                  <GradientBottom targetRGBA={targetRGBA} />
                )
              }
            </div>
          </div>
          <div className={`text-xs pl-4 mt-[0.1rem] mb-[0.8rem] text-gray-800 ${position === "right" ? "text-left pl-5" : "text-right pr-5"}`}>
            {stats}
          </div>
        </div >
      </div>
    </>
  );
};

export default ChatBubble;

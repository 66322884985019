// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider-input {
  -webkit-appearance: none;
  height: 5px;
  background: #4a4a4a;
  outline: none;
}

.slider-input::-webkit-slider-thumb {
  appearance: none;
  width: 20px;
  height: 20px;
  background: #4a4a4a;
  cursor: pointer;
  border-radius: 50%;
}

.slider-input::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #4a4a4a;
  cursor: pointer;
  border-radius: 50%;
  border: none;
}

.truncate-early {
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* display: inline-block; */
}`, "",{"version":3,"sources":["webpack://./src/homepage/components/CostCalculator.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,WAAW;EACX,mBAAmB;EACnB,aAAa;AACf;;AAEA;EAEE,gBAAgB;EAChB,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;EACnB,2BAA2B;AAC7B","sourcesContent":[".slider-input {\n  -webkit-appearance: none;\n  height: 5px;\n  background: #4a4a4a;\n  outline: none;\n}\n\n.slider-input::-webkit-slider-thumb {\n  -webkit-appearance: none;\n  appearance: none;\n  width: 20px;\n  height: 20px;\n  background: #4a4a4a;\n  cursor: pointer;\n  border-radius: 50%;\n}\n\n.slider-input::-moz-range-thumb {\n  width: 20px;\n  height: 20px;\n  background: #4a4a4a;\n  cursor: pointer;\n  border-radius: 50%;\n  border: none;\n}\n\n.truncate-early {\n  max-width: 90%;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  /* display: inline-block; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

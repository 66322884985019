import React, { useState, useEffect, useRef } from 'react';

import claudeAI from '../../assets/compatibleWith/claude-ai.png';
import googleGemini from '../../assets/compatibleWith/google-gemini.png';
import llamaMeta from '../../assets/compatibleWith/llama-meta.png';
import openAILogo from '../../assets/compatibleWith/openai-logo.png';
import mistral from '../../assets/compatibleWith/mistralai-logo.png';

interface Feature {
  top: string;
  title: string;
  description: string;
}

const CompatibleWithSection = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollDirection, setScrollDirection] = useState(1); // 1 for right, -1 for left
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollDirectionRef = useRef(scrollDirection); // Create a ref for scrollDirection

  useEffect(() => {
    scrollDirectionRef.current = scrollDirection; // Update ref whenever scrollDirection changes
  }, [scrollDirection]);

  const logos = [
    { logo: claudeAI, alt: "Claude AI", height: 38 },
    { logo: googleGemini, alt: "Google Gemini", height: 65 },
    { logo: llamaMeta, alt: "Llama Meta", height: 56 },
    { logo: openAILogo, alt: "OpenAI", height: 53 },
    { logo: mistral, alt: "Mistral", height: 48 }
  ];

  const extendedLogos = [...logos, ...logos, ...logos];

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const animateScroll = () => {
      setScrollPosition((prevPosition) => {
        let newDirection = scrollDirectionRef.current;
        let newPosition = prevPosition + newDirection;
        const maxScroll = container.scrollWidth - container.clientWidth;

        if (newPosition >= maxScroll || newPosition <= 0) {
          newDirection = -newDirection;
          scrollDirectionRef.current = newDirection;
          newPosition = prevPosition;
        }

        if (newDirection !== scrollDirectionRef.current) {
          setScrollDirection(newDirection);
        }

        return newPosition;
      });
    };

    const animationId = setInterval(animateScroll, 55);
    return () => clearInterval(animationId);
  }, []);


  return (
    <div className="flex flex-col md:flex-row items-center gap-8 md:max-w-7xl mx-auto justify-between pt-12 w-full md:px-16">
      <div className="w-1/4 min-w-[150px] lg:min-w-[300px]">
        <span className="text-black text-left heading-4 lg:px-4 ">Compatible with</span>
      </div>
      <div className="w-[75%] relative min-w-[200px]">
        <div className="w-full overflow-hidden p-4">
          <div
            ref={containerRef}
            className="flex"
            style={{
              transform: `translateX(${-scrollPosition}px)`,
              transition: 'transform 0.1s linear',
            }}
          >
            {extendedLogos.map((logo, index) => (
              <div key={index} className="flex-shrink-0 mx-14 flex items-center justify-center">
                <img src={logo.logo} alt={logo.alt} style={{ height: `${Math.floor(logo.height / 1.25)}px`, objectFit: 'contain' }} />
              </div>
            ))}
          </div>
        </div>
        <div className="absolute top-0 left-0 bottom-0 w-8 bg-gradient-to-l from-transparent to-yellow"></div>
        <div className="absolute top-0 right-0 bottom-0 w-8 bg-gradient-to-r from-transparent to-yellow"></div>
      </div>
    </div>

  );
};


interface FeatureCardProps {
  top: string;
  title: string;
  description: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({ top, title, description }) => {
  const processTop = (topText: string) => {
    if (topText.includes('/arrow-up')) {
      return (
        <span className="flex items-center">
          {topText.replace('/arrow-up', '')}
          <span style={{ color: '#fa780b' }} className="heading-2-5 ml-1"> ▲</span>
        </span>
      );
    } else if (topText.includes('/arrow-down')) {
      return (
        <span className="flex items-center">
          {topText.replace('/arrow-down', '')}
          <span style={{ color: '#fa780b' }} className="heading-2-5 ml-1"> ▼</span>
        </span>
      );
    }
    return topText;
  };

  return (
    <div className="w-full p-6 bg-soft-yellow rounded-2xl xl:p-12 lg:h-[22rem] lg:w-[26rem]">
      <h3 className="heading-0 mb-2">{processTop(top)}</h3>
      <h3 className="heading-3 mb-4">{title}</h3>
      <p className="s-body-justify mt-6 lg:mt-6">{description}</p>
    </div>
  );
};

const FeaturesSection: React.FC = () => {
  const features: Feature[] = [
    {
      top: "91%/arrow-down",
      title: "Input Costs",
      description: "API expenses climbing? Sqwish slashes costs by redundant input tokens."
    },
    {
      top: "1.8x",
      title: "Faster Inference",
      description: "Longer prompts slow down inference. Compressed prompts boost output token throughput."
    },
    {
      top: "14%/arrow-up",
      title: "Performance",
      description: "Long prompts suffer from 'lost in the middle' syndrome. Sqwish improves retrieval and reasoning performance."
    }
  ];

  return (
    <section className="section bg-yellow pt-8 px-4 pb-16 lg:pb-32 gap-8 lg:gap-16 lg:pt-16 lg:px-16 -mt-16 lg:-mt-16 items-center rounded-t-3xl lg:rounded-t-[2.5rem] relative">
      <div className="max-w-3xl w-full mx-auto flex-col items-center text-center gap-6 mb-8 lg:mb-8">
        <div className="w-full max-w-[50rem] mx-auto flex flex-col items-center text-center gap-6">
          <h3 className="text-black s-body">SUPERCHARGE YOUR AI STACK
          </h3>
          <h2 className="text-black heading-2 w-full max-w-[45rem]">Optimize speed, costs and performance.
          </h2>
          <p className="text-black l-body w-full max-w-[42rem]">Sqwish knows what's important to you: we retain critical information and keep structured text, code and tables intact.</p>
        </div>
      </div>
      <div className="flex flex-col w-full lg:flex-row gap-4 items-center lg:gap-8 max-w-7xl mx-auto justify-center lg:pt-4">
        {features.map((feature, index) => (
          <FeatureCard key={index} top={feature.top} title={feature.title} description={feature.description} />
        ))}
      </div>
      <CompatibleWithSection />
    </section>
  );
};

export default FeaturesSection;
import React, { useEffect } from 'react';

const RequestAccess: React.FC = () => {
  const divStyle: React.CSSProperties = {
    backgroundColor: '#ffc436', // Change this to any color you like
    width: '100%',
    height: '100vh'
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "//embed.typeform.com/next/embed.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div style={divStyle}>
    <div data-tf-live="01J8MTCZT4Z775MZ26NBZ10HFC"></div>
  </div>;
};

export default RequestAccess;